@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

.background {
  background-image: url(../public/assets/background.png);
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, .5);
  background-blend-mode: overlay;
}

@font-face {
  font-family: "PP Gosha Sans";
  /*Can be any text*/
  src: url("../public/fonts/PPGoshaSans-Regular.otf") format("truetype");
}